<script lang="ts">
    export let genders;
    export let searchFilters;
    export let searchTerm;
    export let activeFilter;
</script>

<form class="search-filters-form w-100">
    <div class="card mb-4 rounded-lg">
        <div class="card-body">
            <div class="form-group">
                <label for="gender">Gender</label>
                <select class="form-control" id="gender" name="gender" bind:value="{searchFilters.gender}">
                    <option value="all">All</option>
                    {#each genders as gender}
                        <option value="{gender.gender_name}" selected="{gender.gender_name === searchFilters.gender}"
                            >{gender.gender_name}</option
                        >
                    {/each}
                </select>
                <!-- @if ($errors->has('gender'))
                    <span class="invalid-feedback" role="alert">
                        <strong>{{ $errors->first('gender') }}</strong>
                    </span>
                @endif -->
            </div>

            <div class="w-100 flex flex-row">
                <div class="w-50 pr-2">
                    <div class="form-group">
                        <label for="min_age">Min age</label>
                        <input
                            class="form-control"
                            id="min_age"
                            name="min_age"
                            placeholder="18"
                            type="number"
                            min="18"
                            bind:value="{searchFilters.min_age}"
                        />
                    </div>
                </div>
                <div class="w-50 pl-2">
                    <div class="form-group">
                        <label for="max_age">Max age</label>
                        <input
                            class="form-control"
                            id="max_age"
                            name="max_age"
                            type="number"
                            min="18"
                            bind:value="{searchFilters.max_age}"
                        />
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="location">Location</label>
                <input class="form-control" id="location" name="location" bind:value="{searchFilters.location}" />
            </div>

            <input type="hidden" value="{searchTerm ? searchTerm : ''}" />
            <input type="hidden" value="{activeFilter !== false ? activeFilter : 'top'}" />
        </div>
    </div>
</form>
